import React, { useEffect } from "react";
import { gsap } from "gsap";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
// import Header from "../components/Header"
import Footer from "../components/Footer";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import "../css/orchards.scss";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  EffectFade,
  EffectFlip,
  A11y,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
// import "swiper/components/navigation/navigation.min.css"
// import "swiper/components/pagination/pagination.min.css"
// import "swiper/components/scrollbar/scrollbar.min.css"
// import "swiper/components/effect-fade/effect-fade.min.css";
// import "swiper/components/effect-fade/effect-flip.min.css";
import "swiper/swiper-bundle.min.css";

// import sliderImg1 from "../images/products/product-1.jpg"
// import sliderImg2 from "../images/products/product-4.jpg"
// import sliderImg3 from "../images/products/product-3.jpg"

import GardenBgAppleSm from "../images/gardens/garden-bg-apples.svg";
import GardenBgAppleXl from "../images/gardens/garden-bg-apples-1024.svg";
import SwiperArrow from "../images/arrow-right.svg";

import GardenMap from "../images/gardens/garden-map.png";
import Garden1 from "../images/gardens/garden-1.png";
import Garden2 from "../images/gardens/garden-2.png";
import Garden3 from "../images/gardens/garden-3.png";
import GardenClimate from "../images/gardens/garden-climate.svg";
import GardenGoodTaste from "../images/gardens/garden-good-taste.png";
import GardenBigApple from "../images/gardens/garden-big-apple.svg";

import { Helmet } from "react-helmet";

// install Swiper modules
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectFade,
  EffectFlip,
  A11y,
]);

// SwiperCore.use([Navigation, Pagination, Scrollbar])

// import dot from "../images/dot.svg"

const Gardens = () => {
  const { t } = useTranslation();

  const breakpoints = useBreakpoint();

  if (breakpoints.sm) {
    var GardenBgApple = GardenBgAppleSm;
    console.log("---sm");
  } else {
    var GardenBgApple = GardenBgAppleXl;

    console.log("---big");
  }

  return (
    <Layout>
      {/* <Header /> */}
      <Helmet>
        <title>{t("header")}</title>
      </Helmet>
      <main>
        <div className="page page-garden page-garden_margin">
          <div className="page-title w-2/3 md:w-auto">
            <div className="page-title__name">{t("title")}</div>
          </div>

          <div className="page-header">
            <h1>{t("header")}</h1>
          </div>

          <div className="row box box_map box_first">
            <div className="col box__text md:w-1/3">
              <div className="box__indent">{t("text1Part1")}</div>
              <div className="box__indent">{t("text1Part2")}</div>
            </div>

            <div className="col box__image box__image_map md:w-1/2">
              <img
                className="box__pic box__pic_map"
                src={GardenMap}
                alt="Map of Tula province"
              />
            </div>

            <div className="col box__caption w-2/3 md:w-1/6">
              {t("imageGardenCaption")}
            </div>
          </div>

          <div className="row flex box box_gardens">
            <div className="col col__box-garden w-full md:w-1/3">
              <div className="row">
                <div className="col box__text box__text_garden w-1/3 md:w-full order-2 md:order-1">
                  <div className="box__map-number">1</div>
                  <div className="box__text-garden md:w-full">
                    <Trans>{t("imageDistrict1")}</Trans>
                  </div>
                </div>
                <div className="col box__image box__image_garden w-2/3 md:w-full order-1 md:order-2">
                  <img
                    className="box__pic box__pic_garden"
                    src={Garden1}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="col col__box-garden w-full md:w-1/3">
              <div className="row">
                <div className="col box__text box__text_garden w-1/3 md:w-full order-1 md:order-1">
                  <div className="box__map-number">2</div>
                  <div className="box__text-garden md:w-full">
                    <Trans>{t("imageDistrict2")}</Trans>
                  </div>
                </div>
                <div className="col box__image box__image_garden w-2/3 md:w-full order-2 md:order-1">
                  <img
                    className="box__pic box__pic_garden"
                    src={Garden2}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="col col__box-garden w-full md:w-1/3">
              <div className="row">
                <div className="col box__text box__text_garden w-1/3 md:w-full order-2 md:order-1">
                  <div className="box__map-number">3</div>
                  <div className="box__text-garden md:w-full">
                    <Trans>{t("imageDistrict3")}</Trans>
                  </div>
                </div>
                <div className="col box__image box__image_garden w-2/3 md:w-full order-1 md:order-2">
                  <img
                    className="box__pic box__pic_garden"
                    src={Garden3}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row box box_climate">
            <div className="col box__text md:w-1/3">
              {t("imageClimateCaption")}
            </div>
            <div className="col box__image box__image_climate md:w-1/2">
              <img
                className="box__pic box__pic_climate"
                src={GardenClimate}
                alt="The climate of Tula region"
              />
            </div>
            <div className="col box__text box_climate-value md:w-1/6">
              <div className="row">
                <div className="col box__text box__text_climate w-2/3 md:w-full">
                  {t("imageIndicatorsCaption")}
                </div>
                <div className="col box__text box__text_climate w-1/3 md:w-full">
                  <div className="box__text_climate-name">
                    <span className="box__climate-rain"></span>
                    {t("precipitation")}
                  </div>
                  <div className="box__text_climate-name box__text_climate-temperature">
                    <span className="box__climate-temperature"></span>
                    {t("temperature")}
                  </div>
                </div>
              </div>
            </div>

            <div className="col box__text box_climate-desc w-full md:w-2/3">
              <div className="row">
                <div className="col w-full md:w-1/2 box__indent">
                  {t("climateFact1")}
                </div>
                <div className="col w-full md:w-1/2 box__indent">
                  {t("climateFact2")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page page-max page-bg-apple box_bg-apples box_bg-apples_sm">
          <div className="row">
            <div className="col hiddens xxl36:flexs xxl36:w-1/16">
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
            </div>
            <div className="col hiddens xxl32:flexs xxl32:w-1/14 xxl36:w-1/16">
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
            </div>
            <div className="col hiddens xxl27:flexs xxl27:w-1/12 xxl32:w-1/14 xxl36:w-1/16">
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
            </div>
            <div className="col hiddens xxl23:flexs xxl23:w-1/10 xxl27:w-1/12 xxl32:w-1/14 xxl36:w-1/16">
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
            </div>
            <div className="col hiddens xxl18:flexs xxl18:w-1/8 xxl23:w-1/10 xxl27:w-1/12 xxl32:w-1/14 xxl36:w-1/16">
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
            </div>
            <div className="col xxl18:w-6/8 xxl23:w-3/5 xxl27:w-1/2 xxl32:w-3/7 xxl36:w-3/8">
              <div className="row">
                {/* <div className="col w-1/2 lg:hiddens xl1300flex"> */}
                <div className="col w-1/2 lg:hiddens xl13:flexs">
                  <div className="row">
                    <div className="box_bg-apples__number">44</div>
                  </div>
                </div>

                <div className="col w-1/2 lg:w-full xl13:w-1/2">
                  <div className="row">
                    <div className="col col-apple w-1/3 lg:w-1/6 xl13:w-1/3">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/3 lg:w-1/6 xl13:w-1/3">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/3 lg:w-1/6 xl13:w-1/3">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/3 lg:w-1/6 xl13:w-1/3">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/3 lg:w-1/6 xl13:w-1/3">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/3 lg:w-1/6 xl13:w-1/3">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/6 hiddens lg:flexs xl13:hiddens">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/6 hiddens lg:flexs xl13:hiddens">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/6 hiddens lg:flexs xl13:hiddens">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/6 hiddens lg:flexs xl13:hiddens">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/6 hiddens lg:flexs xl13:hiddens">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/6 hiddens lg:flexs xl13:hiddens">
                      <img src={GardenBgApple} alt="" />
                    </div>
                  </div>
                </div>

                <div className="col w-1/3 lg:hiddens xl13:flexs">
                  <div className="row">
                    <div className="col col-apple w-1/2 xl13:hiddens">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/2 xl13:hiddens">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple col-apple_hide w-1/2 md:hiddens xl13:flexs">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple col-apple_hide w-1/2 md:hiddens xl13:flexs">
                      <img src={GardenBgApple} alt="" />
                    </div>
                  </div>
                </div>

                <div className="col w-1/3 hiddens lg:flexs xl13:hiddens">
                  <div className="row">
                    <div className="col box_bg-apples__number">44</div>
                  </div>
                </div>

                <div className="col col_bg-apples_text w-2/3 md:w-1/2 lg:w-1/3">
                  <div className="row">
                    <div className="col col_bg-apples_text">
                      {t("areaOrchards")}
                    </div>
                  </div>
                </div>

                <div className="col w-1/3 hiddens lg:flexs">
                  <div className="row">
                    <div className="col col-apple w-1/2 hiddens lg:flexs">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/2 hiddens lg:flexs">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/2 hiddens lg:flexs xl13:hiddens">
                      <img src={GardenBgApple} alt="" />
                    </div>
                    <div className="col col-apple w-1/2 hiddens lg:flexs xl13:hiddens">
                      <img src={GardenBgApple} alt="" />
                    </div>
                  </div>
                </div>

                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6">
                  <img src={GardenBgApple} alt="" />
                </div>
                <div className="col col-apple w-1/6 hiddens md:flexs lg:hiddens">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
            </div>
            <div className="col hiddens xxl18:flexs xxl18:w-1/8 xxl23:w-1/10 xxl27:w-1/12 xxl32:w-1/14 xxl36:w-1/16">
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
            </div>
            <div className="col hiddens xxl23:flexs xxl23:w-1/10 xxl27:w-1/12 xxl32:w-1/14 xxl36:w-1/16">
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
            </div>
            <div className="col hiddens xxl27:flexs xxl27:w-1/12 xxl32:w-1/14 xxl36:w-1/16">
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
            </div>
            <div className="col hiddens xxl32:flexs xxl32:w-1/14 xxl36:w-1/16">
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
            </div>
            <div className="col hiddens xxl36:flexs xxl36:w-1/16">
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="col col-apple w-full">
                  <img src={GardenBgApple} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="page-max box_bg-apples box_bg-apples_big">
        <div className="page-for-garden">
            <div className="row box box-apples">
              <div className="col w-1/2 md:w-1/2">
                <div className="box_bg-apples__number">44</div>
              </div>
              <div className="break w-full"></div>
              <div className="col col_bg-apples_text w-2/3 md:w-1/3">
              <div>
                  гектара&nbsp;&mdash; площадь садов Ясной Поляны, вместе
                  с&nbsp;историческими садами на&nbsp;территории усадьбы.
                  </div>
              </div>
              <div className="col w-full box_bg-apple_bottom">&nbsp;</div>
            </div>
          </div>
        </div> */}

        <div className="page page-garden">
          <div className="page-header">
            <h1>{t("subHeader1")}</h1>
          </div>

          <div className="row box">
            <div className="col box__text md:w-1/2">
              <div className="box__indent">{t("text2Part1")}</div>
              <div className="box__indent">{t("text2Part2")}</div>
            </div>

            <div className="col box__image box__image_map md:w-1/2">
              <img
                className="box__pic box__pic_map"
                src={GardenGoodTaste}
                alt="Хороший вкус"
              />
            </div>
          </div>

          <div className="row box box-garden-sorts">
            <div className="col box__text md:w-1/2">
              {/* <div className="box__indent">32</div> */}
              <div className="box_bg-apples__number">33</div>
            </div>
            <div className="col box__text md:w-1/2">
              <div className="box__indent">{t("text3")}</div>
            </div>
          </div>
          {/* </div> */}

          <div className="row box box-sorts">
            {/* <div className=""> */}
            <Swiper
              className="swiper-gardens"
              slidesPerView="auto"
              // effect="fade"
              spaceBetween={50}
              slidesPerView={1}
              breakpoints={{
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 320,
                },
                1300: {
                  slidesPerView: 2,
                  spaceBetween: 450,
                },
              }}
              grabCursor="true"
              loop="true"
              // freeMode="true"
              // navigation
              preventClicks="false"
              preventClicksPropagation="false"
              // pagination={{ clickable: true, type: "fraction" }}
              // scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onClick={(swiper) => swiper.slideNext()}
              onResize={(swiper) => console.log("-----resize")}
            >
              <SwiperSlide className="col box__text w-2/3">
                <div className="">
                  <div className="w-full">{t("swiper1Header")}</div>
                  <div className="box__text box__text_sorts w-full">
                    <div>{t("swiper1Text1")}</div>
                    <div>{t("swiper1Text2")}</div>
                    <div>{t("swiper1Text3")}</div>
                  </div>
                </div>
              </SwiperSlide>

              {/* <SwiperSlide>
                <div className="col box__text w-1/6"></div>
              </SwiperSlide> */}

              <SwiperSlide className="col box__text w-2/3">
                <div className="">
                  <div className="w-full pr-4">{t("swiper2Header")}</div>
                  <div className="box__text box__text_sorts w-full">
                    <div>{t("swiper2Text1")}</div>
                    <div>{t("swiper2Text2")}</div>
                    <div>{t("swiper2Text3")}</div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="col box__text w-2/3">
                <div className="">
                  <div className="w-full pr-4">{t("swiper3Header")}</div>
                  <div className="box__text box__text_sorts w-full">
                    <div>{t("swiper3Text1")}</div>
                    <div>{t("swiper3Text2")}</div>
                  </div>
                </div>
              </SwiperSlide>
              <div className="swiper-arrow">
                <img className="" src={SwiperArrow} alt="" />
              </div>
            </Swiper>
          </div>

          {/* <div className="page page-garden page-garden_margin"> */}
          {/* <div className="row box box-sorts">
            <div className="col box__text w-2/3">
              <div className="w-full">Летние сорта</div>
              <div className="box__text box__text_sorts w-full">
                Мельба Аркад Кальвиль
              </div>
            </div>
            <div className="col box__text w-1/6"></div>
            <div className="col box__text w-1/3">
              <div className="flex justify-between items-center w-full ">
                <div className="w-full pr-4">осенние сорта</div>

              </div>
              <div className="box__text box__text_sorts w-full">
                Коричное Штрейфлин Анис
              </div>
            </div>
          </div> */}

          <div className="row box box-garden-resume">
            <div className="col box__text md:w-1/2 pb-5">{t("resume1")}</div>
            <div className="col box__text md:w-1/2">
              <div className="box__indent">{t("resume2Part1")}</div>
              <div className="box__indent">{t("resume2Part2")}</div>
            </div>
          </div>

          <div className="garden-big-apple">
            <img className="garden-big-apple_pic" src={GardenBigApple}></img>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  );
};

export default Gardens;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["orchards", "common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
